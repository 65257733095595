import { navigate, type PageProps } from 'gatsby'
import React from 'react'
import Container from 'components/Container'
import Head from 'components/Head'
import Layout from 'components/Layout'
import Link from 'components/Link'
import Text from 'components/Text'
import TextWithIcon from 'components/TextWithIcon'
import Home from 'components/landing/Home'
import paths from 'constants/paths'
import storage from 'constants/storage'
import { useGlobalContext } from 'contexts/GlobalContext'
import * as cookie from 'libs/cookie'
import styles from './Home.module.scss'
import { ReactComponent as ArrowRightSvg } from './images/arrow-right.svg'

type Props = PageProps

export default function HomePage({ location }: Props) {
  const globalContext = useGlobalContext()

  React.useEffect(() => {
    // globalContext.isAuthed isn't ready on mount
    // and adding to dep causes redirect away soon as they auth
    if (cookie.getCookie(storage.REFRESH_TOKEN_KEY)) {
      navigate(paths.HOME_AUTHED, { replace: true })
    } else if (paths.getHomePath() !== location.pathname) {
      navigate(paths.getHomePath(), { replace: true })
    }
  }, [location.pathname])

  function handleBannerClick() {
    globalContext.analytics?.trackEvent('Partnerships banner click')
  }

  return (
    <Layout
      headerProps={{
        prepend: (
          <Text
            align="center"
            color="white"
            element="header"
            className={styles['partnerships-banner']}
            onClick={handleBannerClick}>
            <Container size="xxlarge">
              <Text
                element="p"
                flush
                inline
                wrap="balance"
                className={styles['partnerships-banner--copy']}>
                <span className={styles['partnerships-banner--copy---large']}>
                  Improve Star Ratings, drive clinical outcomes, and make members happier &
                  healthier
                </span>
                <span className={styles['partnerships-banner--copy---small']}>
                  Bring Bold to your members
                </span>
              </Text>
              <Link to={paths.PARTNERSHIPS}>
                <TextWithIcon
                  icon={<ArrowRightSvg />}
                  gutterSize="xxxsmall"
                  iconPosition="right"
                  inline
                  weight="bold">
                  Bold for Health Plans
                </TextWithIcon>
              </Link>
            </Container>
          </Text>
        ),
      }}
      className="Home"
      data-test-id="home">
      <Head title="Bold – Variety of online fitness classes for older adults" titleOnly />
      <Home />
    </Layout>
  )
}
